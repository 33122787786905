<template>
  <div class="page__wrapper">
    <ag-grid
      ref="nvrpGrid"
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
    />
  </div>
</template>

<script>
const BASEURL = {
  list: '/jlr/tt/newCarRetailDetails/grossProfit'
}
export default {
  name: 'NewVehicleRetailProfit',
  props: {
    detailData: { type: Object }
  },
  data () {
    return {
      tableData: [
        {
          model: '国产车合计'
        },
        {
          model: 'L538'
        },
        {
          model: 'L550'
        },
        {
          model: 'L551'
        },
        {
          model: 'E-PACE'
        },
        {
          model: 'XEL'
        },
        {
          model: 'XFL'
        },
        {
          model: '国产捷豹其他车型'
        },
        {
          model: '国产路虎其他车型'
        },
        {
          model: '进口车合计'
        },
        {
          model: 'F-Pace'
        },
        {
          model: 'F-Type'
        },
        {
          model: 'I-Pace'
        },
        {
          model: 'XE'
        },
        {
          model: 'XF'
        },
        {
          model: 'XJ'
        },
        {
          model: 'RR'
        },
        {
          model: 'RRS'
        },
        {
          model: 'New Discovery'
        },
        {
          model: 'Velar'
        },
        {
          model: 'Evoque Convertible'
        },
        {
          model: 'Defender110'
        },
        {
          model: 'Defender90'
        },
        {
          model: 'Defender130'
        },
        {
          model: '进口捷豹其他车型'
        },
        {
          model: '进口路虎其他车型'
        },
        {
          model: '合计'
        }
      ],
      isShowDetailDialog: false
    }
  },
  computed: {
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.newVehicleRetailProfit.tableTitle'),
          field: 'tableTitle',
          children: [
            {
              headerName: this.$t('dealer_finance.newVehicleRetailProfit.model'),
              field: 'model',
              pinned: 'left',
              cellStyle: params => {
                if (params.data.model.indexOf('合计') > -1) {
                  return { fontWeight: 600 }
                }
              },
              minWidth: 160
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetailProfit.income'),
              field: 'income',
              valueFormatter: params => this.numberParser(params.data.income, '-'),
              minWidth: 180
            },
            {
              headerName: this.$t('dealer_finance.newVehicleRetailProfit.cost'),
              field: 'cost',
              valueFormatter: params => this.numberParser(params.data.cost, '-'),
              minWidth: 180
            },
            {
              headerName: 'MSRP',
              field: 'msrp',
              valueFormatter: params => this.numberParser(params.data.msrp, '-'),
              minWidth: 180
            },
            {
              headerName: 'TP%',
              field: 'tpPercent',
              valueFormatter: params => this.numberParser(params.data.tpPercent, '未销售', true),
              minWidth: 180
            },
            {
              headerName: 'GPI',
              field: 'gpi',
              valueFormatter: params => this.numberParser(params.data.gpi, '-'),
              minWidth: 180
            },
            {
              headerName: 'GPI%',
              field: 'gpiPercent',
              valueFormatter: params => this.numberParser(params.data.gpiPercent, '未销售', true),
              minWidth: 180
            },
            {
              headerName: 'GPII',
              field: 'gpii',
              valueFormatter: params => this.numberParser(params.data.gpii, '-'),
              minWidth: 180
            },
            {
              headerName: 'GPII%',
              field: 'gpiiPercent',
              valueFormatter: params => this.numberParser(params.data.gpiiPercent, '未销售', true),
              minWidth: 180
            },
            {
              headerName: 'GPIII',
              field: 'gpiii',
              valueFormatter: params => this.numberParser(params.data.gpiii, '-'),
              minWidth: 180
            },
            {
              headerName: 'GPIII%',
              field: 'gpiiiPercent',
              valueFormatter: params => this.numberParser(params.data.gpiiiPercent, '未销售', true),
              minWidth: 180
            }
          ]
        }
      ]
    }
  },
  methods: {
    numberParser (val, defaultVal, percent) {
      return val ? this.$utils.numberFormat(val, { thousand: true, toFixed: 2 }) + (percent ? '%' : '') : defaultVal
    },
    handleDataSearch () {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, { head: { id: this.detailData.id } })
        .then(resp => {
          const respData = resp.data
          const tableData = this.$_.cloneDeep(this.tableData)
          let oData
          for (const i in tableData) {
            oData = respData.find(rsData => {
              return tableData[i].model === rsData.model
            })
            if (oData) {
              this.$set(this.tableData, i, oData)
            }
          }
        })
        .finally(() => {
          loadingFlag.close()
        })
    }
  }
}
</script>
<style lang="less" scoped>
.page__wrapper {
  width: 100%;
  height: 100%;
}
</style>
